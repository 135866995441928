import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService  from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
import { Chart } from 'primereact/chart';

export class StatGnleDep extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            liste_data: [],
            total: 0,
            showindicator: false,
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
                
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.gemsysService = new GestGemsysService();
    }


    get_liste_data() {
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.setState({
            showindicator: true,
            total: 0
        });
        this.gemsysService.get_liste_stat_depense(spd1, spd2).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let tt = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.val
                    }, initialValue);
                    this.setState({
                        total: this.gemsysService.formaterValueSep(tt)
                    });
                }
            }
        });
    }

    conf_print(format) {
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.gemsysService.generer_stat_depense(spd1, spd2, format);
    }

    componentDidMount() {
        this.get_liste_data();
    }


      /* Exportation du tableau  en fichier XLSX */
      exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return {
                'TYPE': x.lib,
                'MONTANT': x.lib2,
                'POURCENTAGE': x.lib3
            }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.gemsysService.saveAsExcelFile(excelBuffer, 'STAT_DEPENSES_');
        });
    }





    render() {
        let liste_label = null;
        let liste_ds_stat = null;

        if (this.state.liste_data != null && this.state.liste_data != undefined) {
            if (this.state.liste_data.length > 0) {
                liste_label = this.state.liste_data.map(x => {
                    return x.lib
                });
                liste_ds_stat = this.state.liste_data.map(x => {
                    return x.val
                });
            }
        }

        const panel_tt = <div className="grid">
            <div className="col-12 md:col-7">
                <label>Total</label>
            </div>
            <div className="col-12 md:col-5">
                <label style={{ color: 'red', fontWeight: 'bold' }}>{this.state.total}</label>
            </div>
        </div>


        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    backgroundColor: [
                        "#0673c7",
                        "#f07d82",
                        "#0d5943",
                        "#2c87b8",
                        "#FF6384",
                        "#36A2EB",
                        "#4071a7",
                        "#fcba03",
                        "#a6a5a2",
                        "#9cb090",
                        "#52ba9b",
                        "#06c1cf",
                        "#275996",
                        "#ff7900",
                        "#003366",
                        "#295380",
                        "#d87a3c",
                        "#36A2EB",
                        "#FFCE56",
                        "#293780",

                    ],
                    data: liste_ds_stat
                }
            ]
        };
 
        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-3" >
                                    <Calendar id="pd1" placeholder="Date début" dateFormat="dd/mm/yy" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Calendar id="pd2" placeholder="Date fin"  dateFormat="dd/mm/yy"  value={this.state.pd2} onChange={e => this.setState({ pd2: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <SplitButton label="Rechercher" style={{ width: '160px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="col-12 md:col-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 md:col-6">
                                    <div className="card card-w-title" style={{ minHeight: '600px' }}>
                                        <Chart type="pie" data={chart_data} height="160w" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="card card-w-title" style={{ minHeight: '600px' }}>
                                        <DataTable value={this.state.liste_data} style={{ minHeight: '500px' }}  ref={(el) => { this.dt = el; }} footer={panel_tt}
                                            dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                            <Column field="ordre" header="#" style={{ width: '10%' }} />
                                            <Column field="lib" header="Type" style={{ width: '50%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                            <Column field="lib2" header="Montant" style={{ width: '23%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                            <Column field="lib3" header="Prc" style={{ width: '17%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}